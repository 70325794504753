import React from 'react';
import SubscriptionUpdates from '../../components/activity/subscription-updates';
import SEO from '../../components/common/seo';
import RobotsView from '../../components/robots/RobotsView';

export default function Robots() {
  return (
    <>
      <SEO title="NavAbility Robots" />
      {/* <Paywall> */}
      <RobotsView/>
      <SubscriptionUpdates />
      {/* </Paywall> */}
    </>
  );
}
