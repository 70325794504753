import { useApolloClient } from '@apollo/client';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import Card from '@mui/material/Card';
import { Theme, createStyles, makeStyles } from '@mui/styles';
import React from 'react';

import { Button } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import { Variable } from 'navabilitysdk';
import NodeViewerBox from '../common/data-views/NodeViewerBox';
import BootstrapDialogTitle from '../common/dialogs/BootstrapDialogTitle';
import { DarkNegativeSendIcon } from '../icons/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      padding: theme.spacing(1),
      margin: theme.spacing(1),
      textAlign: 'left',
      color: theme.palette.text.primary,
    },
    textField: {
      width: '100%',
      margin: theme.spacing(1),
    },
  }),
);

export default function VariableViewerDialog(props: { variable: Variable; onClose: () => void }) {
  const classes = useStyles();
  const navAbilityClient = useApolloClient();

  return (
    <Dialog open={true} onClose={() => props.onClose()} fullWidth maxWidth="xl">
      <BootstrapDialogTitle id="dialog" onClose={() => props.onClose()}>
        Variable: {props.variable.label}
      </BootstrapDialogTitle>
      <DialogContent>
        <Card className={classes.card}>
          <CardContent>
            <NodeViewerBox variableOrFactor={props.variable} />
          </CardContent>
        </Card>
        {/* )} */}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => props.onClose()}
          startIcon={<DarkNegativeSendIcon fontSize="large" />}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
