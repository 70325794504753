import { useApolloClient } from '@apollo/client';
import { MoreHoriz, Refresh, Search } from '@mui/icons-material';
import {
  Box,
  Button,
  CardHeader,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useActions, { useAppDispatch } from '../../app/hooks';
import { UserState, selectUser } from '../../global/state/user-slice';
import { Session } from '../../types/base';
import CircularProgressWithLabel from '../common/CircularProgressWithLabel';
import { HexagonalIcon } from '../icons/icons';
import { RobotsState, selectRobots } from './RobotsSlice';

type Order = 'asc' | 'desc';

/// Headers

interface HeadCell {
  disablePadding: boolean;
  id: keyof Session;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'label',
    label: 'Label',
    numeric: false,
    disablePadding: false,
  },
  // {
  //   id: 'id',
  //   label: 'ID',
  //   numeric: false,
  //   disablePadding: false,
  // },
  {
    id: 'createdTimestamp',
    label: 'Created Timestamp',
    numeric: true,
    disablePadding: false,
  },
  {
    id: 'originLatitude',
    label: 'Origin Latitude',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'originLongitude',
    label: 'Origin Longitude',
    numeric: false,
    disablePadding: false,
  },
];

interface EnhancedTableHeadProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Session) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableHeadProps) {
  const { order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property: keyof Session) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell key="icon"></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box
                  component="span"
                  // sx={visuallyHidden}
                >
                  {/* {order === 'desc' ? 'sorted descending' : 'sorted ascending'} */}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell key="actions" align="center">
          Actions
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

/**
 * Box that shows all sessions, allows a user to filter and upload new blobs.
 * If `onSessionClicked` is set in the props, then when a user clicks a file the file will be sent to the parent.
 *
 * @export
 * @param {({ onSessionClicked?: (session: Session | null) => void })} props
 * @return {*}
 */
export default function SessionsBox(props: { onSessionClicked?: (session: Session | null) => void }) {
  const navAbilityClient = useApolloClient();
  const dispatch = useAppDispatch();
  const actions = useActions();

  const robotsView: RobotsState = useSelector(selectRobots);
  const userState: UserState = useSelector(selectUser);

  // const [state, setState] = useState<{ loading: boolean; error?: string }>({ loading: false });
  // const [files, setFiles] = useState<File[]>([]);
  const [filter, setFilter] = useState<string>('');
  // const [filteredData, setFilteredData] = useState<Robot[]>([]);

  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);

  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Session>('label');

  function handleRequestSort(event: React.MouseEvent<unknown>, property: keyof Session) {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    // Apply the file filter to apply the new order...
    // applyfilter(filter, robotsView.robots);
  }

  async function refresh(appliedFileFilter: string = filter) {
    if (robotsView.selectedRobot) {
      actions.getSessions({
        apolloClient: navAbilityClient,
        userLabel: userState.user?.label,
        robotLabel: robotsView.selectedRobot?.label,
      });
      //Refresh clears the filter for now.
      setFilter('');
    }
  }

  useEffect(() => {
    let paramFilter = '';
    if (typeof window !== `undefined`) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      paramFilter = urlParams.get('searchSession') ?? '';
      setFilter(paramFilter);
    }
    //Because this is async the states have not been updated, we need to pass this down
    refresh(paramFilter);
  }, []);

  // When we get the user, get the robots...
  useEffect(() => {
    if (userState.user && robotsView.selectedRobot) {
      actions.getSessions({
        apolloClient: navAbilityClient,
        userLabel: userState.user?.label,
        robotLabel: robotsView.selectedRobot?.label,
      });
    }
  }, [userState.user?.id, robotsView.selectedRobot?.label]);

  // function navigateBlank(href: string) {
  //   if (typeof window !== `undefined`) {
  //     window.open(href, '_blank');
  //   }
  // }

  async function deleteSession(session: Session) {
    console.error('Not implemented');
  }

  async function handleSessionClicked(session: Session) {
    if (props.onSessionClicked) {
      props.onSessionClicked(session);
    }
  }

  function applyfilter(filterString: string = filter, sessionsToFilter: Session[] = robotsView.sessions) {
    // Filter the data
    var filteredData = sessionsToFilter.filter(
      (r) =>
        r.label.toLowerCase().includes(filterString.toLowerCase()) ||
        r.id.toLowerCase().includes(filterString.toLowerCase()),
    );

    // Now sort it
    if (orderBy == 'label') {
      filteredData = filteredData.sort((a, b) =>
        order == 'asc'
          ? a.label.toLowerCase().localeCompare(b.label.toLowerCase())
          : b.label.toLowerCase().localeCompare(a.label.toLowerCase()),
      );
    }
    if (orderBy == 'createdTimestamp') {
      filteredData = filteredData.sort((a, b) =>
        order == 'asc'
          ? new Date(a.createdTimestamp).valueOf() - new Date(b.createdTimestamp).valueOf()
          : new Date(b.createdTimestamp).valueOf() - new Date(a.createdTimestamp).valueOf(),
      );
    }
    // if (orderBy == 'description') {
    //   filteredData = filteredData.sort((a, b) =>
    //     order == 'asc'
    //       ? a.description.toLowerCase().localeCompare(b.description.toLowerCase())
    //       : b.description.toLowerCase().localeCompare(a.description.toLowerCase()),
    //   );
    // }
    //Now apply it
    return filteredData;
    // setFilteredData(filteredData);
  }

  // Get the filtered list.
  const filteredData = applyfilter(filter);

  return (
    <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
      <CardHeader
        avatar={<HexagonalIcon size="large" color="primary" />}
        title={<Typography variant="h3">Sessions under {robotsView.selectedRobot?.label}</Typography>}
      />
      <Box style={{ display: 'flex' }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search for..."
          onChange={(e) => {
            setFilter(e.target.value);
          }}
          value={filter}
          onKeyUp={(event) => {
            if (event.key == 'Enter') applyfilter();
          }}
        />
        <Button variant="contained" color="secondary" startIcon={<Search />} onClick={() => applyfilter()}>
          Search
        </Button>
      </Box>
      <TableContainer>
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50]}
            count={filteredData.length}
            rowsPerPage={pageSize}
            page={page}
            onPageChange={(event: unknown, newPage: number) => setPage(newPage)}
            onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setPageSize(parseInt(event.target.value, 10))
            }
          />
          <Button
            style={{ height: '50px' }}
            startIcon={<Refresh />}
            variant="outlined"
            onClick={() => refresh()}
            disabled={robotsView.status == 'loading'}
          >
            Refresh
          </Button>
        </Box>
        <Box>
          <Table stickyHeader>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={filteredData.length}
            />

            <TableBody>
              {robotsView.status == 'loading' ? (
                <CircularProgressWithLabel
                  label="Loading..."
                  style={{ width: '96px', height: '96px', margin: '20px' }}
                />
              ) : (
                filteredData.slice(page * pageSize, (page + 1) * pageSize).map((session, index) => (
                  <TableRow key={session.id + session.createdTimestamp + index}>
                    <TableCell align="center">
                      <HexagonalIcon fontSize="large" />
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip title="Select session">
                        <Link onClick={() => handleSessionClicked(session)}>{session.label}</Link>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center">
                      <Typography>{new Date(session.createdTimestamp).toLocaleString()}</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography>{session.originLatitude}</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography>{session.originLongitude}</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip title="View/Edit session">
                        <IconButton disabled={true} edge="end" aria-label="view" onClick={() => {}}>
                          <MoreHoriz fontSize="large" />
                        </IconButton>
                      </Tooltip>
                      {/* <Tooltip title="Delete blob">
                        <IconButton disabled={true} edge="end" aria-label="delete" onClick={() => deleteSession(session)}>
                          <DeleteIcon fontSize="large" />
                        </IconButton>
                      </Tooltip> */}
                      {/* {validFoxgloveRegex.test(session.sessionname) && (
                          <IconButton edge="end" aria-label="foxglove" onClick={() => foxglove(session)}>
                            <SpatialVisualizationIcon fontSize="large" />
                          </IconButton>
                        )}
                        <IconButton edge="end" aria-label="camera" onClick={() => calibrate(session)}>
                          <CameraIcon fontSize="large" />
                        </IconButton> */}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </Box>
      </TableContainer>
    </Box>
  );
}
