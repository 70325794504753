import { useApolloClient } from '@apollo/client';
import { MoreHoriz, Refresh, Search } from '@mui/icons-material';
import {
  Box,
  Button,
  CardHeader,
  Chip,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { QueryDetail, Variable, getVariable, getVariables } from 'navabilitysdk';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useActions, { useAppDispatch } from '../../app/hooks';
import { UserState, selectUser } from '../../global/state/user-slice';
import CircularProgressWithLabel from '../common/CircularProgressWithLabel';
import { DocumentIcon, HexagonalIcon } from '../icons/icons';
import { RobotsState, selectRobots } from './RobotsSlice';
import VariableViewerDialog from './VariableViewerDialog';

type Order = 'asc' | 'desc';

/// Headers

interface HeadCell {
  disablePadding: boolean;
  id: keyof Variable;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'label',
    label: 'label',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'timestamp',
    label: 'Timestamp',
    numeric: true,
    disablePadding: false,
  },
  {
    id: 'variableType',
    label: 'Variable Type',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'tags',
    label: 'Tags',
    numeric: false,
    disablePadding: false,
  },
];

interface EnhancedTableHeadProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Variable) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableHeadProps) {
  const { order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property: keyof Variable) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell key="icon"></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box
                  component="span"
                  // sx={visuallyHidden}
                >
                  {/* {order === 'desc' ? 'sorted descending' : 'sorted ascending'} */}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell key="actions" align="center">
          Actions
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

/**
 * Box that shows all sessions, allows a user to filter and upload new blobs.
 * If `onVariableClicked` is set in the props, then when a user clicks a file the file will be sent to the parent.
 *
 * @export
 * @param {({ onVariableClicked?: (variable: Variable | null) => void })} props
 * @return {*}
 */
export default function VariablesBox(props: { onVariableClicked?: (variable: Variable | null) => void }) {
  const navAbilityClient = useApolloClient();
  const dispatch = useAppDispatch();
  const actions = useActions();

  const [state, setState] = useState<{ loading: boolean; error?: string }>({ loading: false });
  const [variables, setVariables] = useState<Variable[]>([]);
  const [selectedVariable, setSelectedVariable] = useState<Variable | null>(null);

  const robotsView: RobotsState = useSelector(selectRobots);
  const userState: UserState = useSelector(selectUser);

  const [filter, setFilter] = useState<string>('');

  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(20);

  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Variable>('label');

  function handleRequestSort(event: React.MouseEvent<unknown>, property: keyof Variable) {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    // Apply the file filter to apply the new order...
    // applyfilter(filter, robotsView.robots);
  }

  async function refresh(appliedFileFilter: string = filter) {
    setState({ loading: true, error: undefined });
    try {
      if (userState.user && robotsView.selectedRobot && robotsView.selectedSession) {
        let variables = await getVariables(
          navAbilityClient,
          {
            userLabel: userState.user.label,
            robotLabel: robotsView.selectedRobot!.label,
            sessionLabel: robotsView.selectedSession!.label,
          },
          QueryDetail.SUMMARY,
        );
        setVariables(variables);
      }
      // Have to pass in the files because the files variables has not been updated.
      // applyFilter(appliedFileFilter, variables);

      setState({ loading: false, error: undefined });
    } catch (e) {
      setState({ loading: false, error: e });
    }
    //Refresh clears the filter for now.
    setFilter('');
  }

  useEffect(() => {
    let paramFilter = '';
    if (typeof window !== `undefined`) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      paramFilter = urlParams.get('searchVariable') ?? '';
      setFilter(paramFilter);
    }
    //Because this is async the states have not been updated, we need to pass this down
    refresh(paramFilter);
  }, []);

  // When we get the user, get the robots...
  useEffect(() => {
    refresh();
  }, [userState.user?.id, robotsView.selectedRobot?.label, robotsView.selectedSession?.label]);

  function navigateBlank(href: string) {
    if (typeof window !== `undefined`) {
      window.open(href, '_blank');
    }
  }

  async function deleteVariable(variable: Variable) {
    console.error('Not implemented');
  }

  async function handleVariableClicked(summaryVariable: Variable) {
    if (userState.user && robotsView.selectedRobot && robotsView.selectedSession ) {
      let variable = await getVariable(
        navAbilityClient,
        {
          userLabel: userState.user.label,
          robotLabel: robotsView.selectedRobot?.label,
          sessionLabel: robotsView.selectedSession?.label,
        },
        summaryVariable.label,
      );
      setSelectedVariable(variable);
    }
  }

  function applyfilter(filterString: string = filter, variablesToFilter: Variable[] = variables) {
    // Filter the data
    var filteredData = variablesToFilter.filter(
      (r) =>
        r.label.toLowerCase().includes(filterString.toLowerCase()) ||
        r.variableType.toLowerCase().includes(filterString.toLowerCase()) ||
        r.tags.join('|').toLowerCase().includes(filterString.toLowerCase()),
    );

    // Now sort it
    if (orderBy == 'label') {
      filteredData = filteredData.sort((a, b) =>
        order == 'asc'
          ? a.label.toLowerCase().localeCompare(b.label.toLowerCase())
          : b.label.toLowerCase().localeCompare(a.label.toLowerCase()),
      );
    }
    if (orderBy == 'timestamp') {
      filteredData = filteredData.sort((a, b) =>
        order == 'asc'
          ? new Date(a.timestamp).valueOf() - new Date(b.timestamp).valueOf()
          : new Date(b.timestamp).valueOf() - new Date(a.timestamp).valueOf(),
      );
    }
    //Now apply it
    return filteredData;
    // setFilteredData(filteredData);
  }

  // Get the filtered list.
  const filteredData = applyfilter(filter);

  return (
    <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
      <CardHeader
        avatar={<HexagonalIcon size="large" color="primary" />}
        title={<Typography variant="h3">Variables under {robotsView.selectedRobot?.label}</Typography>}
      />
      <Box style={{ display: 'flex' }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search for..."
          onChange={(e) => {
            setFilter(e.target.value);
          }}
          value={filter}
          onKeyUp={(event) => {
            if (event.key == 'Enter') applyfilter();
          }}
        />
        <Button variant="contained" color="secondary" startIcon={<Search />} onClick={() => applyfilter()}>
          Search
        </Button>
      </Box>
      <TableContainer>
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50]}
            count={filteredData.length}
            rowsPerPage={pageSize}
            page={page}
            onPageChange={(event: unknown, newPage: number) => setPage(newPage)}
            onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setPageSize(parseInt(event.target.value, 10))
            }
          />
          <Button
            style={{ height: '50px' }}
            startIcon={<Refresh />}
            variant="outlined"
            onClick={() => refresh()}
            disabled={state.loading}
          >
            Refresh
          </Button>
        </Box>
        <Box>
          <Table stickyHeader>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={filteredData.length}
            />

            <TableBody>
              {state.loading ? (
                <CircularProgressWithLabel
                  label="Loading..."
                  style={{ width: '96px', height: '96px', margin: '20px' }}
                />
              ) : (
                filteredData.slice(page * pageSize, (page + 1) * pageSize).map((variable, index) => (
                  <TableRow key={variable.label + index}>
                    <TableCell align="center">
                      <DocumentIcon fontSize="large" />
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip title="Select variable">
                        <Link onClick={() => handleVariableClicked(variable)}>{variable.label}</Link>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center">
                      <Typography>{new Date(variable.timestamp).toLocaleString()}</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography>{variable.variableType}</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography>
                        {variable.tags.map((t) => (
                          <Chip label={t} onClick={(chip) => setFilter(t)}></Chip>
                        ))}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      {/* <Tooltip title="View/Edit variable"> */}
                      <IconButton disabled={true} edge="end" aria-label="view" onClick={() => {}}>
                        <MoreHoriz fontSize="large" />
                      </IconButton>
                      {/* </Tooltip> */}
                      {/* <Tooltip title="Delete blob">
                        <IconButton disabled={true} edge="end" aria-label="delete" onClick={() => deleteVariable(robot)}>
                          <DeleteIcon fontSize="large" />
                        </IconButton>
                      </Tooltip> */}
                      {/* {validFoxgloveRegex.test(robot.robotname) && (
                          <IconButton edge="end" aria-label="foxglove" onClick={() => foxglove(robot)}>
                            <SpatialVisualizationIcon fontSize="large" />
                          </IconButton>
                        )}
                        <IconButton edge="end" aria-label="camera" onClick={() => calibrate(robot)}>
                          <CameraIcon fontSize="large" />
                        </IconButton> */}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </Box>
      </TableContainer>

      {selectedVariable !== null && (
        <VariableViewerDialog onClose={() => setSelectedVariable(null)} variable={selectedVariable} />
      )}
    </Box>
  );
}
