import { useApolloClient } from '@apollo/client';
import { Engineering, NavigateNext } from '@mui/icons-material';
import { Alert, Box, Breadcrumbs, Button, Card, CardContent, CardHeader, Link, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import useActions, { useAppDispatch } from '../../app/hooks';
import { UserState, selectUser } from '../../global/state/user-slice';
import { Robot, Session } from '../../types/base';
import { HexagonalIcon, SolveSwarmIcon, UserIcon } from '../icons/icons';

import RobotsBox from './RobotsBox';
import { RobotsState, selectRobots, setSelectedRobot, setSelectedSession } from './RobotsSlice';
import SessionsBox from './SessionsBox';
import VariablesBox from './VariablesBox';

export default function RobotsView() {
  const userState: UserState = useSelector(selectUser);
  const robotsView: RobotsState = useSelector(selectRobots);
  const navAbilityClient = useApolloClient();
  const actions = useActions();
  const dispatch = useAppDispatch();

  function onRobotClicked(robot: Robot | null) {
    dispatch(setSelectedRobot(robot));
  }

  function onSessionClicked(session: Session | null) {
    dispatch(setSelectedSession(session));
  }

  return (
    <Box style={{ height: '93vh', overflowY: 'auto' }}>
      <Card>
        <CardHeader
          avatar={<SolveSwarmIcon size="large" color="primary" />}
          title={<Typography variant="h3">Your Robots and Sessions</Typography>}
          subheader="This page lets you explore the data associated with your robots."
        />
        <Alert severity="success" icon={<Engineering/>}>
          This page is in beta testing. Please feel free to explore and if you see any issues please report them to <Link href="mailto:info@navability.io">info@navability.io</Link>.
        </Alert>
        <CardContent>
          {/* The breadcrumbs bar */}
          <Box style={{ marginTop: "20px", marginBottom: '30px' }}>
            <Breadcrumbs separator={<NavigateNext />}>
              <Button startIcon={<UserIcon />} variant="outlined" onClick={() => dispatch(setSelectedRobot(null))}>
                {userState.user.label}
              </Button>
              {robotsView.selectedRobot ? (
                <Button
                  disabled={robotsView.selectedSession == null}
                  startIcon={<SolveSwarmIcon />}
                  variant="text"
                  onClick={() => dispatch(setSelectedSession(null))}
                >
                  {robotsView.selectedRobot.label}
                </Button>
              ) : null}
              {robotsView.selectedSession ? (
                <Button disabled={true} startIcon={<HexagonalIcon />} variant="text">
                  {robotsView.selectedSession.label}
                </Button>
              ) : null}
            </Breadcrumbs>
          </Box>

          {/* Show the robots if none selected, otherwise show sessions */}
          {robotsView.selectedRobot == null ? (
            <RobotsBox onRobotClicked={onRobotClicked} />
          ) : robotsView.selectedSession == null ? (
            <SessionsBox onSessionClicked={onSessionClicked} />
          ) : <VariablesBox />}
        </CardContent>
      </Card>
    </Box>
  );
}
