import { Box } from '@mui/material';
import React from 'react';

import { Factor, Variable } from 'navabilitysdk';
import ReactJson from 'react-json-view';

export default function NodeViewerBox(props: { variableOrFactor: Variable | Factor }) {

  return (
    <Box>
      <ReactJson src={props.variableOrFactor} />
    </Box>
  );
}
